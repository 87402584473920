import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';
import { Section } from '../components/section';

import * as mainStyle from './index.module.css';
import * as styles from './tarifs.module.css';
import * as stylesSection from '../components/section.module.css';

const Tarifs = ({ data }) => {
  return (
    <div className={mainStyle.main}>
      <div>
        <Helmet>
          <meta
            name="description"
            content="Les tarifs de Aude Bien-Être pour les massages, la relaxation et l'expression corporelle"
          />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={styles.mainContent}>
        <Section title="Tarifs">
          <p>
            Pensez au{' '}
            <strong>
              <span>bon cadeau</span>
            </strong>{' '}
            pour différentes occasions : Anniversaire, St Valentin, Noël, Fête des mères, etc...
          </p>
          <p>
            Possibilité{' '}
            <span>
              <a className={stylesSection.link} href="#tarifEntreprise">
                d'atelier en entreprise.
              </a>
            </span>
          </p>
          <h2>Massages</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Durée</th>
                <th>Tarifs</th>
                <th>Tarifs réduits*</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>30 minutes</td>
                <td>40 €uros</td>
                <td>30 €uros</td>
              </tr>
              <tr>
                <td>45 minutes</td>
                <td>55 €uros</td>
                <td>45 €uros</td>
              </tr>
              <tr>
                <td>1 heure</td>
                <td>70 €uros</td>
                <td>60 €uros</td>
              </tr>
              <tr>
                <td>1 heure 30</td>
                <td>90 €uros</td>
                <td>80 €uros</td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: '0px' }} align="right">
            *(étudiants, chômeurs)
          </div>
          {/* <p className={styles.table}>
          <p>Durée</p>
          <p>Tarifs</p>
          <p>Tarifs réduits*</p>
          <p>30 minutes</p>
          <p>30 €uros</p>
          <p>20 €uros</p>
          <p>45 minutes</p>
          <p>45 €uros</p>
          <p>35 €uros</p>
          <p>1 heure</p>
          <p>50 €uros</p>
          <p>50 €uros</p>
          <p>1 heure 30</p>
          <p>90 €uros</p>
          <p>80 €uros</p>
        </p> */}
          <h2>Massage du jeune enfant</h2>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>La séance de 60mn</td>
                <td>50 €uros</td>
              </tr>
            </tbody>
          </table>
          <h2>Découverte et apprentissage du massage bébé (possible à votre domicile)</h2>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Initiation de 60mn</td>
                <td>50 €uros</td>
              </tr>
              <tr>
                <td>5 séances de 45mn (recommandé)</td>
                <td>140 €uros</td>
              </tr>
              <tr>
                <td>5 séances de 60mn</td>
                <td>160 €uros</td>
              </tr>
            </tbody>
          </table>
          <h2>Relaxation</h2>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>En individuel</td>
                <td>40 €uros</td>
              </tr>
              <tr>
                <td>En groupe</td>
                <td>20 €uros</td>
              </tr>
            </tbody>
          </table>
          <h2>Expression corporelle</h2>
          <div style={{ marginBottom: 8 }}>Sous forme de stage en groupe de 8 enfants ou 10 adultes maximum</div>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Adulte</td>
                <td>20 €uros la séance</td>
              </tr>
              <tr>
                <td>Enfant</td>
                <td>10 €uros la séance</td>
              </tr>
            </tbody>
          </table>
          <h2 id="tarifEntreprise">Entreprise</h2>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Forfait sur mesure</td>
                <td>Me consulter pour un devis</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
        </Section>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Tarifs;
